import React, { useEffect } from 'react';

function App() {
  useEffect(() => {
    window.location.href = 'https://kimv0clgipy.typeform.com/to/O7Vgm410';
  }, []);

  return (
    <div class='redirect-message'>
      Redirecting to Plix setup...
      <noscript>
        If you are not redirected, please <a href="https://kimv0clgipy.typeform.com/to/O7Vgm410">click here</a>.
      </noscript>
    </div>
  );
}

export default App;
